import React from 'react';
import './Navbar.style.css';
import beerMug from '../../assets/images/beer-mug.jpg'; // Importing the image from src/assets

function Navbar() {
  return (
    <nav className="navbar flex justify-between bg-gradient-to-r from-pink-500 to-purple-500 p-4">
      <div className="flex items-center">
        <img
          src={beerMug} // Use the imported image as the src
          alt="Beer Mug"
          className="rounded-full border-4 border-white shadow-md mr-4 pixelated w-16 h-16"
        />
        <div>
            
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold pixel-font">
  8-Bit Beer
</h2>
<p className="text-sm sm:text-md md:text-lg text-gray-300 pixel-font">
  Serving cold 8-bit brews
</p>

        </div>
      </div>
      <div className="flex items-center">
        <div className="mr-4">
          <h3 className="text-xl font-semibold pixel-font">Now Playing:</h3>
          <p className="text-sm pixel-font">8-Bit Drunker</p>
        </div>
        <div className="w-16 h-16 bg-pink-500 rounded-full flex items-center justify-center shadow-lg pixelated">
          🎶
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
