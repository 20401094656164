import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import './Footer.style.css';

// Assuming you've downloaded a DexScreener logo and placed it in `src/assets/dexscreener.svg`
import dexScreenerIcon from '../../assets/icons/dex.jpg'; 

function Footer() {
  return (
    <div className="bg-gray-800 p-4 text-center text-gray-400">
      <p className="pixel-font">&copy; 2024 8-bit beer - The ultimate retro brews</p>

      {/* Social Media Links */}
      <div className="flex justify-center mt-4 space-x-4">
        {/* DexScreener Icon */}
        <a href="https://dexscreener.com/solana/eknjny1bzschp6eehpjlnfutyopgcba7dzbtafhnrikm" target="_blank" rel="noopener noreferrer">
          <img src={dexScreenerIcon} alt="DexScreener" className="w-8 h-8 icon" />
        </a>

        {/* Twitter Icon */}
        <a href="https://x.com/8bitbeer" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} className="text-white text-2xl" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
