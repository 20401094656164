import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons'; // Import Telegram icon
import { faCopy } from '@fortawesome/free-solid-svg-icons'; // Copy icon

function ChillZone() {
  const cryptoContract = 'B6mhinGX6EGwhdeTjAsSNhSEFPoxeM2zSEZYTsyHpBj5';

  // Function to copy the contract to the clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(cryptoContract);
    alert('Crypto contract copied to clipboard!');
  };

  return (
    <div className="w-full md:w-1/2 bg-gray-700 p-6 rounded-lg shadow-lg">
      <h3 className="text-3xl font-bold text-purple-400 pixel-font mb-4">Chill Out Zone</h3>
      <p className="text-gray-300 mb-4 pixel-font ">
        Relax with a brew and pixel vibes.
      </p>

      {/* Join the Conversation Button with Telegram Icon */}
      <a
        href="https://t.me/bitbeer_portal"
        target="_blank"
        rel="noopener noreferrer"
        className="flex justify-center items-center w-full px-6 py-3 bg-pink-500 text-white rounded-lg pixel-font hover:bg-pink-600 transition-all duration-200"
      >
        <FontAwesomeIcon icon={faTelegramPlane} className="mr-2" /> {/* Telegram Icon */}
        Join the Conversation
      </a>

      {/* Copy Crypto Contract Section */}
      <div className="flex items-center mt-4 space-x-2">
        <p className="text-gray-300 pixel-font">Copy Contract:</p>
        <button
          onClick={copyToClipboard}
          className="text-white px-3 py-2 bg-gray-600 hover:bg-gray-500 rounded-lg flex items-center"
        >
          {/* Icon to trigger copy */}
          <FontAwesomeIcon icon={faCopy} className="mr-2" />
          <span className="pixel-font">Copy</span>
        </button>
      </div>
    </div>
  );
}

export default ChillZone;
