import React from 'react';
import Navbar from './components/navbar';
import BeerMenu from './components/beerMenu';
import ChillZone from './components/chillZone';
import Footer from './components/footer';
import AudioPlayer from './components/audioPlayer';
import './App.css';

function App() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-purple-900 to-blue-900">
      <AudioPlayer />  
      <Navbar />

      <div className="w-full max-w-4xl bg-gray-800 rounded-lg shadow-lg overflow-hidden pixelated">
        <div className="flex flex-col md:flex-row items-start p-6">
          <BeerMenu />
          <ChillZone />
      
        </div>
          <Footer/>
      </div>

    </div>
  );
}

export default App;
