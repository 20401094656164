import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import './AudioPlayer.style.css';  // Optional custom styles if needed

function AudioPlayer() {
  const [isPlaying, setIsPlaying] = useState(false); // Initially not playing
  const audioRef = useRef(null);

  const toggleMusic = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.muted = false;  // Unmute when the user plays the music
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="audio-player flex items-center justify-center mt-4 mb-8">  {/* Added mb-8 for spacing */}
      {/* Pixel-styled message displayed when music is not playing */}
      {!isPlaying && (
        <p className="text-xl pixel-font text-pink-500 mr-4 pixel-border">
          Play me and buy some 8-bit beers ~
        </p>
      )}

      {/* Play/Pause Button for Background Music */}
      <button onClick={toggleMusic} className="px-4 py-2 bg-purple-600 text-white rounded-lg pixel-font hover:scale-105 transition-transform">
        {isPlaying ? (
          <FontAwesomeIcon icon={faPause} />  // Show Pause icon when music is playing
        ) : (
          <FontAwesomeIcon icon={faPlay} />  // Show Play icon when music is paused
        )}
      </button>

      {/* Hidden Audio Player */}
      <audio ref={audioRef} loop>
        <source src="/BEER.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

export default AudioPlayer;
