import React, { useState } from 'react';
import './BeerMenu.style.css';

function BeerMenu() {
  const [debt, setDebt] = useState(0);
  const [popup, setPopup] = useState(null);

  // Define the sound effect
  const playSound = () => {
    const audio = new Audio('/1.wav');  // Make sure the path is correct
    audio.play();
  };

  const beers = [
    { name: 'Pixel Pilsner', price: 8 },
    { name: '8-Bit IPA', price: 10 },
    { name: 'Retro Lager', price: 12 },
  ];

  const handleBeerClick = (beer) => {
    // Play the sound effect
    playSound();

    const newDebt = debt + beer.price;
    setDebt(newDebt);

    // Show the popup for the beer price
    setPopup({
      name: beer.name,
      price: beer.price,
    });

    // Remove popup after 2 seconds
    setTimeout(() => setPopup(null), 2000);
  };

  return (
    <div className="w-full md:w-1/2 bg-gray-700 p-6 rounded-lg shadow-lg mr-6 relative">
      <h3 className="text-3xl font-bold text-pink-400 pixel-font mb-4">
        Order Beers
      </h3>

      {/* Beer List */}
      <ul className="space-y-2">
        {beers.map((beer, index) => (
          <li
            key={index}
            className="bg-gray-600 p-2 sm:p-3 rounded-lg flex justify-between items-center pixel-font cursor-pointer hover:bg-gray-500 text-sm sm:text-base md:text-lg"
            onClick={() => handleBeerClick(beer)}
          >
            <span>{beer.name}</span>
            <span className="text-pink-400">${beer.price}</span>
          </li>
        ))}
      </ul>

      {/* Debt Display */}
      <div className="mt-4 text-pink-400 pixel-font">
        <strong>Total Debt:</strong> ${debt}
      </div>

      {/* Popup for Beer Consumption */}
      {popup && (
        <div className="absolute top-0 right-0 bg-gray-900 text-white p-2 rounded-lg shadow-lg pixel-font">
          <strong>{popup.name}</strong>: -${popup.price}
        </div>
      )}
    </div>
  );
}

export default BeerMenu;
